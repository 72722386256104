import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { ProjectTool } from "../../../../Common/api/models/ProjectTool";
import NavigationPrompt from "../../../../Common/components/NavigationPrompt/NavigationPrompt";
import { ToolModel } from "../../../CreateProject/models/ToolTile";
import useProjectToolsForm from "../../hooks/useProjectToolsForm";
import AddToolsPopup from "../AddToolsPopup/AddToolsPopup";
import ProjectToolsTable from "../ProjectToolsTable/ProjectToolsTable";
import styles from "../ProjectToolsTable/ProjectToolsTable.module.scss";

interface ProjectToolsFormProps {
  projectTools: ProjectTool[];
  mandatoryTools: ToolModel[];
  optionalTools: ToolModel[];
  userCanEdit: boolean;
}
const ProjectToolsForm = ({
  projectTools,
  mandatoryTools,
  optionalTools,
  userCanEdit,
}: ProjectToolsFormProps) => {
  const filterTools = () => {
    return optionalTools.filter((tool) => {
      return !toolsTableList?.find((tools) => {
        return (
          tool.capabilityToolKey === tools.capabilityToolKey ||
          (tools.capabilityToolKey === "BACKLOG:JIRA" &&
            tool.capabilityToolKey === "BACKLOG:JIRACLOUD")
        );
      });
    });
  };

  const {
    form,
    handleDelete,
    handleFinalize,
    handleAdd,
    handleSubmit,
    isSaving,
    isFinalizing,
    toolsTableList,
  } = useProjectToolsForm(projectTools);

  const [availableTools, setAvailableTools] = useState(filterTools);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    setAvailableTools(filterTools);
  }, [optionalTools, toolsTableList]);

  return (
    <>
      <NavigationPrompt
        when={isDirty}
        title="Do you want to leave this page?"
        description="You have unsaved changes that will be lost if you decide to continue."
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSaving || isFinalizing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={"92%"}
        paddingBottom={"8px"}
      >
        <Typography variant={"h6"}>Tools ({toolsTableList?.length})</Typography>
        {userCanEdit && (
          <Button
            variant="contained"
            onClick={() => setIsModalOpen((open) => !open)}
            size="medium"
          >
            ADD TOOLS
          </Button>
        )}
      </Box>

      <Typography variant={"body2"} sx={{ marginBottom: "16px" }}>
        Overview of the tools. To add users to a tool, go to the Users tab.
      </Typography>
      <AddToolsPopup
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleAddTool={handleAdd}
        tools={availableTools}
      />

      <form>
        <ProjectToolsTable
          form={form}
          tools={toolsTableList}
          mandatoryTools={mandatoryTools}
          userCanEdit={userCanEdit}
          handleDelete={handleDelete}
          handleFinalize={handleFinalize}
        />
        {isDirty && userCanEdit && (
          <div className={styles.toolDetails__footer}>
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              size="large"
              sx={{ mr: 3 }}
            >
              Save
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
export default ProjectToolsForm;
